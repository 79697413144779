import React from "react";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Post from "../components/Post";
import Sidebar from "../components/Sidebar";

class IndexRoute extends React.Component {
  render() {
    const items = [];
    const { title, subtitle, author } = this.props.data.site.siteMetadata;
    const description = subtitle;
    const url = typeof window !== "undefined" ? window.location.href : "";
    const origin = typeof window !== "undefined" ? window.location.origin : "";
    const profilePic = require("./logo.png");
    const posts = this.props.data.allMarkdownRemark.edges;
    posts.forEach((post) => {
      items.push(<Post data={post} key={post.node.fields.slug} />);
    });
    const metaTitle = `${title} - ${subtitle}`;
    return (
      <Layout>
        <div>
          <Helmet>
            <title>{metaTitle}</title>
            <meta name="description" content={description} />
            <link rel="canonical" href={url} />
            <meta property="og:url" content={`${url ? url : origin}`} />
            <meta property="og:type" content="article" />
            <meta property="og:title" content={metaTitle} />
            <meta property="og:description" content={description} />
            <meta property="og:image" content={`${origin}${profilePic}`} />

            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:site" content={author.twitter} />
            <meta name="twitter:title" content={metaTitle} />
            <meta name="twitter:description" content={description} />
            {/* <meta
              name="twitter:image"
              content={`${origin}${encodeURI(
                profilePic
              )}?${new Date().getTime()}`}
            /> */}
            <meta name="monetization" content="$ilp.uphold.com/QzG6Ww2rKUzq" />

          </Helmet>
          <Sidebar {...this.props} />
          <div className="content">
            <div className="content__inner">{items}</div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default IndexRoute;

export const pageQuery = graphql`
  query IndexQuery {
    site {
      siteMetadata {
        title
        subtitle
        copyright
        menu {
          label
          path
        }
        feature {
          label
          path
        }
        deployed {
          label
          path
        }
        author {
          name
          email
          telegram
          twitter
          github
          rss
          vk
        }
      }
    }
    allMarkdownRemark(
      limit: 1000
      filter: { frontmatter: { layout: { eq: "post" }, draft: { ne: true } } }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          fields {
            slug
            categorySlug
            readingTime {
              text
            }
          }
          frontmatter {
            title
            date
            category
            description
            showYear
          }
        }
      }
    }
  }
`;
